import Vue from 'vue'
import { fetchTranscript } from '@/utils/fetcher'

const transcript = {
  state: {
    transcript: [],
  },
  getters: {
    transcript: (state) => state.transcript,
  },
  mutations: {
    setTranscript(state, transcript) {
      Vue.set(state, 'transcript', transcript.sentences)
    },
  },
  actions: {
    async getTranscript(context, episodeId) {
      const res = await fetchTranscript(episodeId)
      context.commit('setTranscript', res.data)
    },
  },
}

export default transcript
