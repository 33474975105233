import Vue from 'vue'
import AdoriService from '@/services/adori'

const experiences = {
  state: {
    experiencesById: {},
    tagsById: {},
    experiences: [],
  },
  getters: {
    experiencesById: (state) => (expId) => state.experiencesById[expId],
    experiences: (state) => state.experiences,
    tagsById: (state) => (id) => state.tagsById[id],
  },
  mutations: {
    setExperiences(state, experiences) {
      Vue.set(state, 'experiences', experiences)
      experiences.forEach((exp) => {
        Vue.set(state.experiencesById, exp.id, exp)
        const tag = { ...exp.tag, ...{ expId: exp.id } }
        Vue.set(state.tagsById, tag.id, tag)
      })
    },
    setExperience(state, experience) {
      Vue.set(state.experiencesById, experience.id, experience)
    },
  },
  actions: {
    async getExperiences(context, episodeId) {
      const res = await AdoriService.fetchExperiences(episodeId)
      context.commit('setExperiences', res.data.experiences)
    },
    async getDecodedExperiences(context, url) {
      const res = await AdoriService.fetchDecodedExperiences(url)
      context.commit('setExperiences', res.data.experiences)
    },
    async setExperiences(context, experiences) {
      context.commit('setExperiences', experiences)
    },
    async setDecodedExperiences(context, experiences) {
      context.commit('setExperiences', experiences)
    },
    async getExperience(context, expId) {
      const res = await AdoriService.fetchExperience(expId)
      context.commit('setExperience', res.data)
    },
    async setExperience(context, experience) {
      context.commit('setExperience', experience)
    },
  },
}

export default experiences
