const player = {
  state: {
    audio: null,
    receiver: null,
    playing: false,
    srcAdded: false,
    duration: null,
    currentTime: null,
    playbackRate: null,
    episode: null,
    startTime: 0,
    stopTime: -1,
  },
  getters: {
    audio: (state) => state.audio,
    receiver: (state) => state.receiver,
    playing: (state) => state.playing,
    srcAdded: (state) => state.srcAdded,
    duration: (state) => state.duration,
    currentTime: (state) => state.currentTime,
    playbackRate: (state) => state.playbackRate,
    episode: (state) => state.episode,
    startTime: (state) => state.startTime,
    stopTime: (state) => state.stopTime,
  },
  mutations: {
    setAudio: (state, audio) => {
      state.audio = audio
    },
    setReceiver: (state, receiver) => {
      state.receiver = receiver
    },
    setAudioSrc: (state, src) => {
      if (state.audio !== null) {
        state.audio.src = src
        state.srcAdded = true
      }
    },
    setPlaying: (state) => {
      state.playing = true
    },
    setPaused: (state) => {
      state.playing = false
    },
    setDuration: (state, duration) => {
      state.duration = duration
    },
    setCurrentTime: (state, currentTime) => {
      state.currentTime = currentTime
    },
    seekAudio: (state, newTime) => {
      state.audio.currentTime = newTime
    },
    setPlaybackRate: (state, playbackRate) => {
      state.playbackRate = playbackRate
    },
    changePlaybackRate: (state, playbackRate) => {
      state.audio.playbackRate = playbackRate
    },
    setEpisode: (state, episode) => {
      state.episode = episode
    },
    setStartTime: (state, startTime) => {
      state.audio.currentTime = startTime
      state.startTime = startTime
    },
    setStopTime: (state, stopTime) => {
      state.stopTime = stopTime
    },
  },
  actions: {
    setAudio: (context, audio) => {
      context.commit('setAudio', audio)
    },
    setReceiver: (context, receiver) => {
      context.commit('setReceiver', receiver)
    },
    setAudioSrc: (context, src) => {
      context.commit('setAudioSrc', src)
    },
    setPlaying: (context) => {
      context.commit('setPlaying')
    },
    setPaused: (context) => {
      context.commit('setPaused')
    },
    setDuration: (context, duration) => {
      context.commit('setDuration', duration)
    },
    setCurrentTime: (context, currentTime) => {
      context.commit('setCurrentTime', currentTime)
    },
    seekAudio: (context, newTime) => {
      context.commit('seekAudio', newTime)
    },
    setPlaybackRate: (context, playbackRate) => {
      context.commit('setPlaybackRate', playbackRate)
    },
    changePlaybackRate: (context, playbackRate) => {
      context.commit('changePlaybackRate', playbackRate)
    },
    setEpisode: (context, episode) => {
      context.commit('setEpisode', episode)
    },
    setStartTime: (context, startTime) => {
      context.commit('setStartTime', startTime)
    },
    setStopTime: (context, stopTime) => {
      context.commit('setStopTime', stopTime)
    },
  },
}

export default player
