import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'portraitaudio',
      component: () => import('@/views/AdoriPlayer.vue'),
    },
    {
      path: '/demo/:episodeId',
      name: 'Demo',
      component: () => import('@/views/DemoIframe.vue'),
    },
    {
      path: '/demov1/:episodeId',
      name: 'DemoV2',
      component: () => import('@/views/DemoV1.vue'),
    },
    {
      path: '/demov2/:episodeId',
      name: 'DemoV2',
      component: () => import('@/views/DemoV2.vue'),
    },
    {
      path: '/e/:episodeid',
      name: 'landscapeadori',
      component: () => import('@/views/AdoriPlayer.vue'),
    },
    {
      path: '/l/:episodeid',
      name: 'landscape',
      component: () => import('@/views/AdoriPlayer.vue'),
    },
    {
      path: '/:episodeid',
      name: 'portraitadori',
      component: () => import('@/views/AdoriPlayer.vue'),
    },
    {
      path: '/v2/:episodeid',
      name: 'playerv2',
      component: () => import('@/views/AdoriPlayerV2.vue'),
    },
  ],
})
