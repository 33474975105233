import axios from 'axios'
import store from '@/store'
import ERRORS from '@/utils/errorMessages'
import { ADORI_API_V6_BASE_URL } from '@/constants'
import axiosRetry from 'axios-retry'

const uuidv1 = require('uuid/v1')

const getApiKey = () => {
  if (store.getters.apiKey) return store.getters.apiKey
  const server: any = window
  const apiKeyFromServerData = server.SERVER_DATA && server.SERVER_DATA.apiKey && server.SERVER_DATA.apiKey.key
  if (apiKeyFromServerData) return apiKeyFromServerData

  try {
    const apiKey = require('@/apiKey')
    return typeof apiKey === 'object' ? null : apiKey
  } catch (error) {
    console.log('apiKey module not found!')
    return null
  }
}

const getUserId = () => {
  if (store.getters.userId) return store.getters.userId

  try {
    if (localStorage.getItem('adoriUserId')) return localStorage.getItem('adoriUserId')
  } catch (error) {
    console.log(ERRORS.localStorageInaccessible)
  }
  const userId = uuidv1()
  try {
    localStorage.setItem('adoriUserId', userId)
  } catch (error) {
    console.log(ERRORS.localStorageInaccessible)
  }
  return userId
}

const retryDelay = (retryNumber = 0) => {
  return retryNumber * 5000
}

export default {
  webplayerAnalytics(payload) {
    return AdoriAPI.post(`/sdk/analytics/record`, payload)
  },

  submitPoll(expId, choice) {
    return AdoriAPI.post(`/sdk/polls/experiences/${expId}?choice=choice${choice}`)
  },

  fetchExperiences(episodeUid) {
    return AdoriAPI.get(`/sdk/episodes/${episodeUid}/experiences`)
  },

  fetchExperience(expId) {
    return AdoriAPI.get(`/sdk/experiences/${expId}`)
  },

  likeAnExperiece(expId) {
    return AdoriAPI.post(`/sdk/likes/experiences/${expId}`)
  },

  unlikeAnExperience(expId) {
    return AdoriAPI.delete(`/sdk/likes/experiences/${expId}`)
  },

  fetchDecodedExperiences(url) {
    return AdoriAPI.post(`/sdk/decoder/experiences`, { audioUrl: url })
  },

  fetchShowEpisodes(showUid, next: any = null) {
    if (next) {
      next = encodeURIComponent(next)
      return AdoriAPI.get(`/sdk/shows/${showUid}/episodes?next=${next}`)
    }
    return AdoriAPI.get(`/sdk/shows/${showUid}/episodes`)
  },

  fetchEpisodeDetails(episodeUid, showUid = '') {
    if (showUid) {
      return AdoriAPI.get(`/sdk/shows/${showUid}/episodes/${episodeUid}`)
    }
    return AdoriAPI.get(`/sdk/episodes/${episodeUid}`)
  },
}

export const AdoriAPI = axios.create({
  baseURL: ADORI_API_V6_BASE_URL,
})

axiosRetry(AdoriAPI, {
  retries: 2,
  retryDelay,
  // retry on Network Error & 5xx responses
  retryCondition: axiosRetry.isRetryableError,
})

AdoriAPI.interceptors.request.use((config) => {
  const apiKey = getApiKey()
  const userId = getUserId()

  if (!(getApiKey() && getUserId())) throw new axios.Cancel('No token or userId')

  config.headers['x-adori-sdk-app-api-key'] = apiKey
  config.headers['x-adori-sdk-listener-id'] = userId

  return config
})

AdoriAPI.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const response = error.response
    if (!response) {
      return Promise.reject(new Error('No response.'))
    } else if (error && error.response && error.response.status === 500) {
      return Promise.reject(new Error(error.response || 'Something went wrong.'))
    }
    throw response
    // return response
  }
)
