import axios from 'axios'
import { BACKEND_API_BASE_URL } from '@/constants'

let authToken = null
let netowrkId = null
axios.defaults.baseURL = BACKEND_API_BASE_URL

function useAuthToken(token) {
  authToken = token
  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`
}

function useNetworkId(id) {
  netowrkId = id
}

async function getEpisodeMetadata(episodeId) {
  if (authToken) {
    return axios.get(`/networks/${netowrkId}/audiotracks/${episodeId}`)
  }
  return axios.get(`/episodes/${episodeId}`)
}

async function getShowEpisodes(uid, next = 0) {
  const res = await axios.get(`/shows/${uid}/episodes?next=${next}`)
  return res.data
}

function getAudioUrl(audiotrackId) {
  if (authToken !== null && netowrkId) {
    return `${axios.defaults.baseURL}/networks/${netowrkId}/audiotracks/${audiotrackId}\
      /audio?api_token=${authToken}`
  }
  return `${axios.defaults.baseURL}/audiotracks/${audiotrackId}/audio`
}

function getImageUrl(imageId) {
  return `${axios.defaults.baseURL}/images/${imageId}`
}

function getThumbnailImageUrl(imageId) {
  return `${axios.defaults.baseURL}/images/${imageId}/thumbnail`
}

async function getAllTagPositions(audiotrackId) {
  if (authToken) {
    return axios.get(`/networks/${netowrkId}/audiotracks/${audiotrackId}/tags`)
  }
  return axios.get(`/episodes/${audiotrackId}/tags`)
}

async function fetchTranscript(episodeUid: string) {
  return axios.get(`/episodes/${episodeUid}/transcript`)
}

export {
  useAuthToken,
  getAudioUrl,
  getImageUrl,
  getThumbnailImageUrl,
  getAllTagPositions,
  getEpisodeMetadata,
  getShowEpisodes,
  useNetworkId,
  fetchTranscript,
}
