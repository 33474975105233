import Vue from 'vue'
import Vuex from 'vuex'
import player from './player'
import experiences from './experiences'
import setup from './setup'
import transcript from './transcript'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    player,
    experiences,
    setup,
    transcript,
  },
})
