import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Toast from 'vue-toastification'
import Vue2TouchEvents from 'vue2-touch-events'
import 'vue-toastification/dist/index.css'

Vue.config.productionTip = false

// https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01
document.documentElement.style.setProperty('--vh', `${vh}px`)

window.addEventListener('resize', () => {
  let vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
})

Vue.use(Vue2TouchEvents, {
  swipeTolerance: 50
})

const options = {
  position: 'bottom-right',
  type: 'success',
  timeout: 2500,
  closeOnClick: true,
  pauseOnFocusLoss: false,
  pauseOnHover: false,
  draggable: true,
  draggablePercent: 0.6,
  hideProgressBar: true,
  // showCloseButtonOnHover: false,
  closeButton: false,
  icon: false,
  rtl: false,
  transition: 'my-custom-fade',
  maxToasts: 1,
  filterBeforeCreate: (toast, toasts) => {
    return toasts.length ? false : toast
  }
}

Vue.prototype.$store = store
Vue.use(Toast, options)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
