export default {
  episode: 'Episode not found!',
  experiences: 'Unable to fetch tags or experiences!',
  audiogram: 'Unable to fetch audiogram',
  analytics: 'Unable to send analytics!',
  decode: 'Unable to decode episode or experiences!',
  redirectUrl: 'Unable to find redirected URL',
  requestCancel: 'Request cancelled by user',
  localStorageInaccessible: 'Local storage is inaccessible',
  pollAlreadySubmitted: 'User already taken the poll',
}
