<template>
  <router-view style="100vh" class="rubik no-select" />
</template>

<script>
export default {}
</script>

<style>
/* Custom fonts */
:root {
  --brandColor: #de1a23;
}
button:focus {
  border: none;
  outline: none;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.limiter {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.my-custom-fade-enter-active {
  animation-name: fadeIn;
  animation-delay: 0.2s;
}
.my-custom-fade-leave-active {
  animation-name: fadeOut;
}
.my-custom-fade-move {
  transition-timing-function: ease-in-out;
  transition-property: all;
  transition-duration: 400ms;
}
.Vue-Toastification__container {
  z-index: 999999 !important;
}
/* @media only screen and (max-width: 600px){ */
.Vue-Toastification__toast {
  border-radius: 0px;
  margin-bottom: 0rem !important;
  padding: 10px !important;
  min-height: 0 !important;
}
/* } */
.Vue-Toastification__toast--error {
  background-color: #de1a23 !important;
}
@media only screen and (max-width: 600px) {
  .dim:hover {
    opacity: 1;
  }
}

.bar-loader {
  width: 130px;
  height: 105.6px;
  --c: linear-gradient(#de1a23 0 0);
  background: var(--c) 0% 50%, var(--c) 25% 50%, var(--c) 50% 50%, var(--c) 75% 50%, var(--c) 100% 50%;
  background-size: 15.8px 50%;
  background-repeat: no-repeat;
  animation: bars-7s9ul0hg 1.2s infinite linear alternate;
}

@keyframes bars-7s9ul0hg {
  20% {
    background-size: 15.8px 20%, 15.8px 50%, 15.8px 50%;
  }

  40% {
    background-size: 15.8px 100%, 15.8px 20%, 15.8px 50%;
  }

  60% {
    background-size: 15.8px 50%, 15.8px 100%, 15.8px 20%;
  }

  80% {
    background-size: 15.8px 50%, 15.8px 50%, 15.8px 100%;
  }
}

.rubik {
  font-family: 'Rubik', sans-serif;
}
.unica-one {
  font-family: 'Unica One', cursive;
}
.permanent-marker {
  font-family: 'Permanent Marker', cursive;
}
.marck-script {
  font-family: 'Marck Script', cursive;
}
.dosis {
  font-family: 'Dosis', sans-serif;
}
.inter-ui {
  font-family: 'Inter', sans-serif;
}
@supports (font-variation-settings: normal) {
  .inter-ui {
    font-family: 'Inter var', sans-serif;
  }
}
/* Rules for sizing the icon. */
.material-icons.md-18 {
  font-size: 18px;
}
.material-icons.md-24 {
  font-size: 24px;
}
.material-icons.md-36 {
  font-size: 36px;
}
.material-icons.md-48 {
  font-size: 48px;
}
/* Rules for using icons as black on a light background. */
.material-icons.md-dark {
  color: rgba(0, 0, 0, 0.54);
}
.material-icons.md-dark.md-inactive {
  color: rgba(0, 0, 0, 0.26);
}
/* Rules for using icons as white on a dark background. */
.material-icons.md-light {
  color: rgba(255, 255, 255, 1);
}
.material-icons.md-light.md-inactive {
  color: rgba(255, 255, 255, 0.3);
}
.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}
.pass-through {
  pointer-events: none;
}
/* Custom scrollbar (Chrome and Safari) */
.scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: transparent;
}
.scrollbar::-webkit-scrollbar:hover {
  background: transparent;
}
.scrollbar::-webkit-scrollbar-track {
  background: none;
}
.scrollbar::-webkit-scrollbar-thumb {
  width: 6px;
  background: rgba(255, 255, 255, 0.3);
  border-color: transparent;
  border-radius: 3px;
}
.scrollbar::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.4);
}
/* Custom colors */
.adori-red {
  color: #de1a23;
}
.medium-gray {
  color: #5a5a5a;
}
.b--adori-red {
  border-color: #de1a23;
}
.b--adori-gray {
  border-color: #3f3f3f;
}
.b--adori-light-gray {
  border-color: #9b9b9b;
}
.b--adori-transparent {
  border-color: transparent;
}
.bg-adori-red {
  background: #de1a23;
}
.bg-adori-gray {
  background: #212121;
}
.bg-adori-medium-gray {
  background: #2f2f2f;
}
.bg-adori-light-gray {
  background: #313131;
}
.bg-adori-very-light-gray {
  background: #5a5a5a;
}
body {
  height: 100%;
  background: black;
  /* border: 1px solid black; */
}
.tracked-05 {
  letter-spacing: 0.05rem;
}
a:link,
a:visited,
a:hover,
a:active,
.adori-link {
  /* color: #de1a23; */
  color: var(--brandColor);
  background-color: transparent;
  text-decoration: underline;
}
.default-cursor {
  cursor: default;
}
</style>
