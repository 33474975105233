import Vue from 'vue'
// import AdoriService from '@/services/adori'

const setup = {
  state: {
    // Permissions
    allowBookmarks: false,
    allowClips: false,
    allowPlaylist: false,
    allowSleepTime: false,
    allowShare: true,
    allowLike: false,
    allowOtherEpisodes: true,

    // Events
    isLiked: false,
    isPlaylisted: false,
    isBookmarkClip: false,
    isFirstTimePlay: true,
    isOtherEpisodesVisible: false,

    // Brand
    brandColor: '#de1a23',
    secondaryColor: '',
    userId: null,
    apiKey: null,
    isBrandedSite: false,
    episodeInfo: null,
  },
  getters: {
    // Permissions
    allowBookmarks: (state) => state.allowBookmarks,
    allowClips: (state) => state.allowClips,
    allowPlaylist: (state) => state.allowPlaylist,
    allowSleepTime: (state) => state.allowSleepTime,
    allowShare: (state) => state.allowShare,
    allowLike: (state) => state.allowLike,
    allowOtherEpisodes: (state) => state.allowOtherEpisodes,

    // Events
    isLiked: (state) => state.isLiked,
    isPlaylisted: (state) => state.isPlaylisted,
    isBookmarkClip: (state) => state.isBookmarkClip,
    isFirstTimePlay: (state) => state.isFirstTimePlay,
    isOtherEpisodesVisible: (state) => state.isOtherEpisodesVisible,
    brandColor: (state) => state.brandColor,
    episodeInfo: (state) => state.episodeInfo,

    // Brand
    userId: (state) => state.userId,
    apiKey: (state) => state.apiKey,
    isBrandedSite: (state) => state.isBrandedSite,
  },
  mutations: {
    setPlayerPermissions: (state, permissions) => {
      Vue.set(state, 'allowClips', permissions.allowClips || false)
      Vue.set(state, 'allowShare', permissions.allowShare || false)
      Vue.set(state, 'allowPlaylist', permissions.allowPlaylist || false)
      Vue.set(state, 'allowBookmarks', permissions.allowBookmarks || false)
      Vue.set(state, 'allowSleepTime', permissions.allowSleepTime || false)
      Vue.set(state, 'allowLike', permissions.allowLike || false)
      Vue.set(state, 'allowOtherEpisodes', permissions.allowLike || true)
    },
    setPlayerPermission: (state, permission) => {
      Vue.set(state, permission.key, permission.value)
    },
    setBrandColors: (state, theme) => {
      Vue.set(state, 'brandColor', theme.brandColor || 'red')
      Vue.set(state, 'secondaryColor', theme.secondaryColor || '')
      document.documentElement.style.setProperty('--brandColor', theme.brandColor || '#de1a23')
    },
    setEventsData: (state, eventsData) => {
      Vue.set(state, 'isLiked', eventsData.isLiked || false)
      Vue.set(state, 'isPlaylisted', eventsData.isPlaylisted || false)
      Vue.set(state, 'isBookmarkClip', eventsData.isBookmarkClip || false)
    },
    setEpisodeInfo: (state, info) => {
      Vue.set(state, 'episodeInfo', info)
    },
    setEventData: (state, eventData) => {
      Vue.set(state, eventData.key, eventData.value)
    },
    setUserId: (state, userId) => {
      Vue.set(state, 'userId', userId)
    },
    setApiKey: (state, apiKey) => {
      Vue.set(state, 'apiKey', apiKey)
    },
    setIsFirstTimePlay: (state, value) => {
      Vue.set(state, 'isFirstTimePlay', value)
    },
    setIsBrandedSite: (state, isBrandedSite) => {
      Vue.set(state, 'isBrandedSite', isBrandedSite)
    },
  },
  actions: {
    setupWebSDK: (context, setupData) => {
      context.commit('setPlayerPermissions', setupData)
      context.commit('setBrandColors', setupData)
      context.commit('setIsBrandedSite', setupData.isBrandedSite)
      context.commit('setEpisodeInfo', setupData.episodeInfo)
    },
    setUserId: (context, userId) => {
      context.commit('setUserId', userId)
    },
    setApiKey: (context, apiKey) => {
      context.commit('setApiKey', apiKey)
    },
    setPlayEpisodeData: (context, playEpisode) => {
      context.commit('setEventsData', playEpisode)
    },
    setPlayerPermission: (context, permission) => {
      context.commit('setPlayerPermission', permission)
    },
    setEventData: (context, eventData) => {
      context.commit('setEventData', eventData)
    },
    setIsFirstTimePlay: (context, value) => {
      context.commit('setIsFirstTimePlay', value)
    },
  },
}

export default setup
